// src/components/Contact.js
// import React from 'react';
// import './Contact.css';
// import { motion } from 'framer-motion';

// const Contact = () => {
//   return (
//     <div className="contact">
//       <h2>Contact Me</h2>
//       <form>
//         <motion.input
//           type="text"
//           placeholder="Your Name"
//           whileFocus={{ borderColor: '#000' }}
//         />
//         <motion.input
//           type="email"
//           placeholder="Your Email"
//           whileFocus={{ borderColor: '#000' }}
//         />
//         <motion.textarea placeholder="Your Message" whileFocus={{ borderColor: '#000' }} />
//         <motion.button type="submit" whileHover={{ scale: 1.1 }}>
//           Send
//         </motion.button>
//       </form>
//     </div>
//   );
// };

// export default Contact;

// src/components/Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact">
      <h2>Contact Me (Still WIP!)</h2>
      <form>
        <input type="text" placeholder="Your Name" />
        <input type="email" placeholder="Your Email" />
        <textarea placeholder="Your Message"></textarea>
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default Contact;
