// src/components/About.js
// import React from 'react';
// import './About.css';

// const About = () => {
//   return (
//     <div className="about">
//       <h2>About Me</h2>
//       <p className="about-text">
//         Placeholder text for your bio. You can add information about your experience, interests, and personal background here.
//       </p>
//     </div>
//   );
// };

// export default About;

// src/components/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h2>About Me</h2>
      <p>
        Hello! I'm James Chen. I am still rebuilding the site. Please enjoy the animation in the meantime.
      </p>
    </div>
  );
};

export default About;
