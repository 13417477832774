// src/components/Portfolio.js
// import React from 'react';
// import './Portfolio.css';
// import { motion } from 'framer-motion';

// const Portfolio = () => {
//   const projects = [
//     { name: 'Project One', description: 'A cool project description.' },
//     { name: 'Project Two', description: 'Another project description.' },
//     { name: 'Project Three', description: 'Something amazing here.' },
//   ];

//   return (
//     <div className="portfolio">
//       <h2>Portfolio</h2>
//       <div className="portfolio-grid">
//         {projects.map((project, index) => (
//           <motion.div
//             className="project-card"
//             key={index}
//             whileHover={{ scale: 1.1 }}
//             initial={{ opacity: 0 }}
//             animate={{ opacity: 1 }}
//             transition={{ delay: index * 0.3 }}
//           >
//             <h3>{project.name}</h3>
//             <p>{project.description}</p>
//           </motion.div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Portfolio;


// src/components/Portfolio.js
import React from 'react';
import './Portfolio.css';

const Portfolio = () => {
  return (
    <div className="portfolio">
      <h2>My Projects</h2>
      <p>
      </p>
    </div>
  );
};

export default Portfolio;
