// src/App.js
import React from 'react';
import { Link, Element } from 'react-scroll';
import './App.css';
import LandingPage from './components/LandingPage';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      {/* Navigation Bar */}
      <nav className="navbar">
        {/* Placeholder Text */}
        <div className="navbar-Title">James Chen</div>
        
        {/* Navigation Links */}
        <ul>
          <li>
            <Link to="landingPage" smooth={true} duration={800}>
              Home
            </Link>
          </li>
          <li>
            <Link to="aboutSection" smooth={true} duration={800}>
              About
            </Link>
          </li>
          <li>
            <Link to="portfolioSection" smooth={true} duration={800}>
              Portfolio
            </Link>
          </li>
          <li>
            <Link to="contactSection" smooth={true} duration={800}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>

      {/* Sections */}
      <Element name="landingPage">
        <LandingPage />
      </Element>

      <Element name="aboutSection">
        <About />
      </Element>

      <Element name="portfolioSection">
        <Portfolio />
      </Element>

      <Element name="contactSection">
        <Contact />
      </Element>

      <Footer />
    </div>
  );
}

export default App;
