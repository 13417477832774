// src/components/LandingPage.js
import React, { useEffect } from 'react';
import './LandingPage.css';

const LandingPage = () => {

  // JavaScript to generate stars
  useEffect(() => {
    const starContainer = document.querySelector('.star-container');
    const numberOfStars = 300;

    for (let i = 0; i < numberOfStars; i++) {
      let star = document.createElement('div');
      star.className = 'star';
      let size = Math.random() * 3 + 1;  // Random size between 1 and 3px
      let top = Math.random() * 100;     // Random top position
      let left = Math.random() * 100;    // Random left position
      star.style.width = `${size}px`;
      star.style.height = `${size}px`;
      star.style.top = `${top}%`;
      star.style.left = `${left}%`;

      const twinkleDuration = Math.random() * 5 + 2;  // Random duration between 2 and 7 seconds
      const brightness = Math.random() * 1.5 + 0.5;   // Random brightness between 0.5 and 2

      star.style.animation = `twinkle ${twinkleDuration}s infinite`;
      star.style.filter = `brightness(${brightness})`;

      starContainer.appendChild(star);
    }
  }, []);

  return (
    <div className="landing">
      {/* Starfield background */}
      <div className="star-container"></div>

      {/* Solar System */}
      <div className="solar-system">
        {/* Sun */}
        <div className="sun">
          {/* Planets orbiting the Sun */}
          <div className="planet mercury"></div>
          <div className="planet venus"></div>
          <div className="planet earth"></div>
          <div className="planet mars"></div>
          <div className="planet jupiter"></div>
          <div className="planet saturn">
            <div className="ring"></div>
          </div>
          <div className="planet uranus"></div>
          <div className="planet neptune"></div>
        </div>
      </div>

      {/* Main content */}
      <div className="content">
        <h1></h1>
        <p></p>
      </div>
    </div>
  );
};

export default LandingPage;
